/*!********************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/rocket/submodules/app_preview/app_preview.scss ***!
  \********************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.app-preview {
  display: flex;
  flex-wrap: wrap;
}
.app-preview .app-screen {
  height: 300px;
  width: 100%;
  aspect-ratio: 1/1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
